// @font-face {
//     font-family: 'avenir';
//     src: local('avenir'), url(./assets/fonts/avenir.otf) format('truetype');
// }

// *{
//     font-family:avenir;
// }

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

*{
    font-family: 'Helvetica', 'Arial', sans-serif;
}

html{
    scroll-behavior: smooth !important;
}

body {

    background-color: #000000 !important;

    .loading-message {
        margin-left: auto !important;
        margin-right: auto !important;
        color: #ffffff !important;

        position: absolute;
        top: 50%; 
        left: 50%;
    }

}